import React, { useEffect, useState } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useField } from "formik";

type Props = {
  name: string;
  min: number;
  max: number;
  // Default value is 100%, this var defines what will be the default value when
  // the user decides to have a fixed width instead
  defaultFixedValue: number;
};

const PortalWidthField: React.FC<Props> = ({
  name,
  min,
  max,
  defaultFixedValue,
}) => {
  const [field, , helpers] = useField(name);
  const [isWidthFull, setWidthFullState] = useState(true);

  const width = field.value;

  useEffect(() => {
    if (isWidthFull && width !== "100%") {
      helpers.setValue("100%");
    }

    if (!isWidthFull && width === "100%") {
      helpers.setValue(defaultFixedValue);
    }
  }, [isWidthFull, helpers, width, defaultFixedValue]);

  return (
    <Flex
      flexDir={["column", "column", "row"]}
      flex="1"
      justifyContent="space-between"
      alignItems="baseline"
      mb="4"
    >
      <FormControl mx="4" maxW={["100%", "250px"]} mb={["4", "0"]}>
        <FormLabel fontSize="xl">Responsive</FormLabel>
        <Checkbox
          size="lg"
          mt="1"
          mb="2"
          isChecked={isWidthFull}
          onChange={() => setWidthFullState((f) => !f)}
          data-testid="widget-portal-options-responsive"
        >
          Auto width
        </Checkbox>
      </FormControl>

      <FormControl mx="4" maxW={["100%", "100%", "160px"]} mb={["4", "0"]}>
        <FormLabel fontSize="xl">Width</FormLabel>

        <NumberInput
          id={name}
          defaultValue={defaultFixedValue}
          min={min}
          max={max}
          step={10}
          onChange={(val) => {
            const nextVal = Number(val);
            if (nextVal > max) {
              helpers.setValue(max);
            } else {
              helpers.setValue(nextVal);
            }
          }}
          placeholder="Width"
          name={name}
          onBlur={() => {
            if (field.value < min) {
              helpers.setValue(min);
            }

            if (field.value > max) {
              helpers.setValue(max);
            }
          }}
          isDisabled={isWidthFull}
        >
          <NumberInputField data-testid="widget-portal-options-width" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </Flex>
  );
};

export default PortalWidthField;
