import {
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

type NumberFieldProps = {
  name: string;
  label: string;
  min: number;
  max: number;
};

const PortalNumberField: React.FC<NumberFieldProps> = ({
  name,
  label,
  min,
  max,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  return (
    <FormControl mx="4" maxW={["100%", "100%", "160px"]} mb={["4", "0"]}>
      <FormLabel fontSize="xl">{label}</FormLabel>
      <NumberInput
        id={name}
        defaultValue={field.value}
        min={min}
        max={max}
        step={10}
        onChange={(val) => {
          const nextVal = Number(val);
          if (nextVal > max) {
            helpers.setValue(max);
          } else {
            helpers.setValue(nextVal);
          }
        }}
        placeholder={label}
        name={name}
        onBlur={() => {
          if (field.value < min) {
            helpers.setValue(min);
          }

          if (field.value > max) {
            helpers.setValue(max);
          }
        }}
      >
        <NumberInputField {...props} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
};

export default PortalNumberField;
