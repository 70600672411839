import { Flex, Button, Text } from "@chakra-ui/react";
import { useField, Field } from "formik";
import React from "react";
import type { IconType } from "react-icons";

type Props = {
  name: string;
  value: string;
  label: string;
  hint: string;
  icon: IconType;
};

const PortalRadioField: React.FC<Props> = ({
  name,
  value,
  label,
  hint,
  icon: Icon,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  const handleClick = () => {
    helpers.setValue(value);
  };

  const active = field.value === value;

  return (
    <Flex flexDir="column" flex="1" alignItems="center" mb={["4", "4", "0"]}>
      <Button
        p="2"
        display="flex"
        border="2px"
        bg="gray.50"
        borderColor={active ? "blue.400" : "gray.300"}
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        w="40"
        h="20"
        onClick={handleClick}
        {...props}
      >
        <Flex
          flexDir="column"
          alignItems="center"
          color={active ? "blue.400" : "black"}
        >
          <Icon className="text-xl" />

          <Text mt="2" fontSize="lg">
            {label}
          </Text>
        </Flex>
      </Button>

      <Text fontStyle="italic" fontSize="xs" mt="2">
        {hint}
      </Text>

      <Field
        type="radio"
        style={{ display: "none" }}
        name={name}
        value={value}
      />
    </Flex>
  );
};

export default PortalRadioField;
