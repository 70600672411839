import { Flex } from "@chakra-ui/react";
import React from "react";
import { FaMoon, FaSun, FaMagic } from "react-icons/fa";
import PortalRadioField from "./PortalRadioField";

const WidgetThemeField: React.FC = () => {
  return (
    <Flex mb="6" flexDir={["column", "column", "row"]}>
      <PortalRadioField
        name="theme"
        label="Dark"
        hint="Force dark theme"
        value="dark"
        icon={FaMoon}
        data-testid="widget-portal-theme-dark"
      />

      <PortalRadioField
        name="theme"
        label="Light"
        hint="Force light theme"
        value="light"
        icon={FaSun}
        data-testid="widget-portal-theme-light"
      />

      <PortalRadioField
        name="theme"
        label="Auto"
        hint="Adapts to browser settings"
        value="auto"
        icon={FaMagic}
        data-testid="widget-portal-theme-auto"
      />
    </Flex>
  );
};

export default WidgetThemeField;
