import { Flex } from "@chakra-ui/react";
import React from "react";
import PortalNumberField from "./PortalNumberField";
import PortalToggleField from "./PortalToggleField";
import PortalWidthField from "./PortalWidthField";

type Props = {
  tracklistDisabled: boolean;
};

const WidgetRegularOptions: React.FC<Props> = ({ tracklistDisabled }) => {
  return (
    <Flex mx="16" flexDir="column">
      <PortalWidthField
        name="width"
        min={210}
        max={1000}
        defaultFixedValue={400}
      />

      <Flex
        flexDir={["column", "column", "row"]}
        flex="1"
        justifyContent="space-between"
        alignItems="baseline"
        mb={["4", "0"]}
      >
        <PortalToggleField
          title="Tracklist"
          label="Show Tracklist"
          name="tracklist"
          disabled={tracklistDisabled}
          hint={
            tracklistDisabled
              ? "Tracklist cannot be visible at this size"
              : undefined
          }
          data-testid="widget-portal-options-show-tracklist"
        />
        <PortalNumberField
          label="Height"
          name="height"
          min={150}
          max={1000}
          data-testid="widget-portal-options-height"
        />
      </Flex>
    </Flex>
  );
};

export default WidgetRegularOptions;
